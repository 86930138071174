<template>
  <div class="related collections">
    <header>
      <div class="h2">
        <nuxt-link :to="`/watch?genre=${docs.title}`">
          {{ docs.title }}
        </nuxt-link>
      </div>
    </header>
    <slider-component :videos="{ items: docs.items, type: 'watch' }" />
  </div>
</template>

<script>
export default {
  props: { docs: { type: Object, required: true, default: () => ({})  } },
  components: {
    sliderComponent: () => import('~/components/sliderComponent.vue')
  }
}
</script>
